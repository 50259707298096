export default {
  breadcrumb: {
    athletesCards: 'Members / Cards / Athletes Cards',
    coachesCards: 'Members / Cards / Coaches Cards',
    instructorsCards: 'Members / Cards / Instructors Cards',
    umpiresCards: 'Members / Cards / Umpires Cards',
    dragonulCards: 'Members / Cards / Dragonul Cards',
  },
  athletesCards: 'Athletes Cards',
  birthDate: 'Birth Date',
  cardIdDate: 'Card ID Date',
  cards: 'Cards',
  cardsReport: 'Cards_Report',
  category: 'Category',
  categoryDate: 'Category Date',
  club: 'Club',
  coach: 'Coach',
  coachesCards: 'Coaches Cards',
  createdAt: 'Created At',
  date: 'Date',
  dateType: 'Date Type',
  degree: 'Degree',
  dragonulCards: 'Dragonul Cards',
  fee: 'Fee',
  firstname: 'First Name',
  instructor: 'Instructor',
  instructorsCards: 'Instructors Cards',
  issueDate: 'Issue Date',
  lastname: 'Last Name',
  memberId: 'Member ID',
  memberName: 'Member Name',
  noCardIdDate: 'No Card Id Date for the selected member. The card won\'t be saved',
  noPhoto: 'The selected member has no photo. The card won\'t be saved',
  operand: 'Operand',
  serial: 'Serial No',
  sportsman: 'Sportsman',
  umpire: 'Umpire',
  umpiresCards: 'Umpires Cards',
}