import Vue from 'vue'
import VueI18n from 'vue-i18n'
import languages from './languages';

import ro from './ro'
import en from './en'

Vue.use(VueI18n);

const messages = {ro: ro, en: en};

export default new VueI18n({locale: JSON.parse(localStorage.getItem('selectedLocale')) || languages[0].locale, messages})
