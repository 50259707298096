import activities from './activities'
import alerts from './alerts'
import belts from './belts'
import cards from './cards'
import categories from './categories'
import clubs from './clubs'
import datatable from './datatable'
import degrees from './degrees'
import errors from './errors'
import events from './events'
import global from './global'
import licenses from './licenses'
import login from './login'
import members from './members'
import registry from './registry'
import users from './users'
import validators from './validators'
import visas from "./visas"

export default {
  message: {
    activities,
    alerts,
    belts,
    cards,
    categories,
    clubs,
    datatable,
    degrees,
    errors,
    events,
    global,
    licenses,
    login,
    members,
    registry,
    users,
    validators,
    visas,
  }
}
