import Vue from 'vue'
import Router from 'vue-router'

//routes
import routes from './routes';

// session components
const LoginOne = () => import('@/views/session/LoginOne');
const Auth0CallBack = () => import('@/components/Auth0Callback/Auth0Callback');

Vue.use(Router);

export default new Router({
  mode: 'history',
  scrollBehavior: function (to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 }
  },
  routes: [
    routes,
    {
      path: '/callback',
      component: Auth0CallBack
    },
    {
      path: '/session/login',
      component: LoginOne
    },
  ],

})
