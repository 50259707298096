/**
 * AUTH config file
 */

export default {
  audience: 'nhVO4kZ1v3xrlgRnhK5OHluOiEkxldRG',
  callbackUrl: process.env.NODE_ENV === 'development' ? 'http://localhost:8080/callback' : 'https://system.taekwondo-itf.ro/callback',
  clientId: 'nhVO4kZ1v3xrlgRnhK5OHluOiEkxldRG',
  domain: 'frtkd.eu.auth0.com',
  logoutUrl: process.env.NODE_ENV === 'development' ? 'https://frtkd.eu.auth0.com/v2/logout?returnTo=http://localhost:8080' :
    'https://frtkd.eu.auth0.com/v2/logout?returnTo=https://system.taekwondo-itf.ro',
}
