export default {
  breadcrumb: {
    competitions: "Evenimente / Competiții",
    seminar: "Evenimente / Stagii / Stagiu",
    seminars: "Evenimente / Stagii",
  },
  aPerson: "O Persoană",
  activities: "Activități",
  activity: "Activitate",
  addAthlete: "Adaugă sportiv",
  addAttendant: "Adaugă participant",
  addCoach: "Adaugă instructor",
  addMember: "Adaugă membru",
  addTeam: "Adaugă echipă",
  addUmpire: "Adaugă arbitru",
  amounts: "Sume",
  amountsByActivity: "Sume per activitate",
  amountsByClub: "Sume per club",
  athleteSearch: "Caută sportiv",
  attendantSearch: "Caută participant",
  birthDate: "Data Nașterii",
  categories: "Categorii",
  categAndComp: "Categorii & Competitori (Individual)",
  children: "Copii",
  city: "Localitate",
  closed: "Închis",
  club: "Club",
  clubFees: "Taxe Club",
  clubReports: "Rapoarte club",
  coachSearch: "Caută instructor",
  coaches: "Instructori",
  competitions: "Competiții",
  competitorsInCategories: "Competitori in categorii",
  country: "Țară",
  currency: "Monedă",
  degree: "Grad",
  detailsView: "Vezi Detalii",
  endDate: "Dată Sfârșit",
  enroll: "Înscrie",
  errorNoGdpr: "Persoana trebuie să trimită un formular GDPR semnat.",
  errorNoId: "Persoana trebuie să trimită un document de identitate.",
  errorNoPhoto: "Persoana trebuie să aibă foto.",
  eurFee: "Taxă EUR",
  eventName: "Denumirea evenimentului",
  events: "Evenimente",
  federation: "Federație",
  federationReports: "Rapoarte federație",
  fee: "Taxă",
  feeReport: "Raport taxe",
  fees: "Taxe",
  finalized: "Finalizate",
  grandTotal: "Total General",
  juniors: "Juniori",
  latestAthleteVisa: "Ultima viză de sportiv",
  latestInstructorVisa: "Ultima viză de instructor",
  latestUmpireVisa: "Ultima viză de arbitru",
  maxDegree: "Grad Maxim",
  members: "Membri",
  minDegree: "Grad Minim",
  name: "Nume",
  noClubSelected:
    "Te rog să selectezi un club pentru a afișa conținutul acestui tab.",
  notifications: "Notificări",
  ongoing: "În desfășurare",
  open: "Deschis",
  organizer: "Organizator",
  other: "Altceva",
  participants: "Participanți",
  personName: "Numele persoanei",
  persons: "Persoane",
  registeredByActivity: "Înregistrați per activitate",
  registeredByClub: "Înregistrați per club",
  registration: "Înscriere",
  registrationAthletes: "Înscriere sportivi",
  registrationCoaches: "Înscriere instructori",
  registrationEnd: "Ultima zi de înscrieri",
  registrationTeams: "Înscriere echipe",
  registrationUmpires: "Înscriere arbitri",
  remove: "Elimină",
  reports: "Rapoarte",
  riskWaver: "Responsabilitatea accidentelor",
  ronFee: "Taxă RON",
  seminar: "Stagiu",
  seminars: "Stagii",
  seniors: "Seniori",
  startDate: "Dată Start",
  status: "Stare",
  teamName: "Name echipă",
  teamSearch: "Caută echipă",
  total: "Total",
  type: "Tip",
  umpireSearch: "Caută arbitru",
  umpires: "Arbitri",
  visas: "Vize",
  warningNoPlaque:
    "Persoana are 4 Dan sau mai mult dar nu a selectat placheta de instructor AETF.",
  warningNoRegistration: "Persoana nu este înscrisă la nici o activitate.",
};
