export default [
  {
    name: "Română",
    icon: "ro",
    locale: "ro"
  },
  {
    name: "English",
    icon: "en",
    locale: "en"
  }
]
