import auth0 from 'auth0-js'
import AuthConfig from 'src/config/auth'
import EventEmitter from 'eventemitter3'
import router from 'src/router'
import store from 'src/store';

class AuthService {

  constructor () {
    this.login = this.login.bind(this);
    this.setSession = this.setSession.bind(this);
    AuthService.isAuthenticated = AuthService.isAuthenticated.bind(this);
    this.authenticated = AuthService.isAuthenticated();
    this.authNotifier = new EventEmitter();
    this.scheduleRenewal = this.scheduleRenewal.bind(this);
    this.auth0 = new auth0.WebAuth({
      domain: AuthConfig.domain,
      clientID: AuthConfig.clientId,
      redirectUri: AuthConfig.callbackUrl,
      audience: AuthConfig.audience,
      responseType: 'token id_token',
      scope: 'openid email profile'
    });
  }

  login () {
    this.auth0.authorize();
  }

  handleAuthentication () {
    this.auth0.parseHash({ hash: window.location.hash }, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        router.replace('/dashboard');
      } else if (err) {
        router.replace('/');
        console.log(err);
      }
    })
  }

  setSession (authResult) {
    store.dispatch('signInUserWithAuth0', authResult);
    this.scheduleRenewal();
    this.authNotifier.emit('authChange', {authenticated: true});
  }

  static isAuthenticated () {
    // Check whether the current time is past the access token's expiry time
    return new Date().getTime() < store.getters.expiresAt;
  }

  renewToken () {
    this.auth0.checkSession({}, (err, result) => {
      if (err) {
        console.log(err);
        store.dispatch('logoutUserFromAuth0')
      } else {
        this.setSession(result);
      }
    });
  }

  scheduleRenewal () {
    const delay = store.getters.expiresAt - Date.now();
    if (delay > 0) {
      setTimeout(() => {this.renewToken()}, delay);
    }
  }
}

export default AuthService;
