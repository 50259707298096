/**
 * Vuely Global Components
 */
import AppCard from 'src/components/global/AppCard';
import AppSectionLoader from 'src/components/global/AppSectionLoader';
import AppOverlayLoader from 'src/components/global/AppOverlayLoader';
import BackTop from 'src/components/global/BackTop.vue'
import Croppa from 'vue-croppa'
import DeleteConfirmationDialog from 'src/components/global/DeleteConfirmationDialog';
import PageTitleBar from 'src/components/global/PageTitleBar';
import SectionTooltip from 'src/components/global/SectionTooltip'
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

const GlobalComponents = {
  install (Vue) {
    Vue.component('appCard', AppCard);
    Vue.component('appSectionLoader', AppSectionLoader);
    Vue.component('appOverlayLoader', AppOverlayLoader);
    Vue.component('backTop', BackTop);
    Vue.component('my-croppa', Croppa.component);
    Vue.component('deleteConfirmationDialog', DeleteConfirmationDialog);
    Vue.component('pageTitleBar', PageTitleBar);
    Vue.component('sectionTooltip', SectionTooltip);
    Vue.component('vuePerfectScrollbar', VuePerfectScrollbar);
  }
};

export default GlobalComponents
