export default {
  breadcrumb: {
    activities: 'Activități',
  },
  activities: 'Activități',
  fee: 'Taxă',
  maxAge: 'Vârstă max',
  maxDegree: 'Grad max',
  minAge: 'Vârstă min',
  minDegree: 'Grad min',
  name: 'Nume',
}