/**
 * API v2 config file
 */

import axios from "axios";

export default axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:3011/api/v2"
      : "https://app.taekwondo-itf.ro/api/v2",
});
