import './lib/VuelyCss'
import "core-js/stable";
import "regenerator-runtime/runtime";
import App from './App'
import AuthService from "./lib/AuthService";
import fullscreen from 'vue-fullscreen'
import GlobalComponents from './lib/globalComponents'
import i18n from './locales'
import Notifications from 'vue-notification'
import Nprogress from 'nprogress'
import router from './router'
import store from './store';
import helpers from './mixins/helpers';
import velocity from 'velocity-animate'
import Vue from 'vue'
import VueBreadcrumbs from 'vue2-breadcrumbs'
import VueResource from 'vue-resource'
import Vuetify from 'vuetify'
const VueUploadComponent = require('vue-upload-component');

const vuetifyOpts = {theme: {themes: {light: store.getters.selectedTheme.theme}}};
const auth = new AuthService();
auth.scheduleRenewal();

router.beforeEach((to, from, next) => {
  Nprogress.start();
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (store.getters.idToken === null || (store.getters.expiresAt - Date.now()) <= 0) {
      next({
        path: '/session/login',
        query: {redirect: to.fullPath}
      })
    } else {
      store.dispatch('cancelWaitingProcesses');
      next()
    }
  } else {
    store.dispatch('cancelWaitingProcesses');
    next() // make sure to always call next()!
  }
});

router.afterEach((to, from) => {
  Nprogress.done();
  setTimeout(() => {
    const contentWrapper = document.querySelector(".v-content__wrap");
    if (contentWrapper !== null) {
      contentWrapper.scrollTop = 0;
    }
    const boxedLayout = document.querySelector('.app-boxed-layout .app-content');
    if (boxedLayout !== null) {
      boxedLayout.scrollTop = 0;
    }
    const miniLayout = document.querySelector('.app-mini-layout .app-content');
    if (miniLayout !== null) {
      miniLayout.scrollTop = 0;
    }
  }, 200);
});

// plugins
Vue.use(Vuetify);
Vue.use(VueResource);
Vue.use(VueBreadcrumbs);
Vue.use(Notifications, {velocity});
Vue.use(fullscreen);
Vue.use(GlobalComponents);

/* eslint-disable no-new */
new Vue({
  store,
  i18n,
  router,
  helpers,
  vuetify: new Vuetify(vuetifyOpts),
  render: h => h(App),
  components: {
    App,
    'file-upload': VueUploadComponent
  }
}).$mount('#app');
