import primaryTheme from 'src/config/themes/primaryTheme';
import dangerTheme from 'src/config/themes/dangerTheme';
import warningTheme from 'src/config/themes/warningTheme';
import infoTheme from 'src/config/themes/infoTheme';
import accentTheme from 'src/config/themes/accentTheme';
import successTheme from 'src/config/themes/successTheme';

// sidebar filters
export const sidebarFilters = [
  {
    id: 1,
    class: 'sidebar-overlay-primary',
    name: 'message.general.primary'
  }
];

// router animations
export const routerAnimations = [
  'slideInUp',
  'fadeIn',
  'slideInRight',
  'bounce',
  'lightSpeedIn',
  'pulse'
];

// themes
export const themes = [
  {
    id: 1,
    bgColor: "bg-primary",
    theme: primaryTheme
  },
  {
    id: 2,
    bgColor: "bg-warning",
    theme: warningTheme
  },
  {
    id: 3,
    bgColor: "bg-success",
    theme: successTheme
  },
  {
    id: 4,
    bgColor: "bg-danger",
    theme: dangerTheme
  },
  {
    id: 5,
    bgColor: "bg-info",
    theme: infoTheme
  },
  {
    id: 6,
    bgColor: "bg-secondary",
    theme: accentTheme
  }
];

// header filter
export const headerFilters = [
  {
    id: 1,
    class: "primary"
  },
  {
    id: 2,
    class: "light"
  },
  {
    id: 3,
    class: "warning"
  },
  {
    id: 4,
    class: "success"
  },
  {
    id: 5,
    class: "error"
  },
  {
    id: 6,
    class: "info"
  }
];
