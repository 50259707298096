export default {
  breadcrumb: {
    athletesCards: 'Membri / Carduri / Carduri Sportiv',
    coachesCards: 'Membri / Carduri / Carduri Antrenor',
    instructorsCards: 'Membri / Carduri / Carduri Instructor',
    umpiresCards: 'Membri / Carduri / Carduri Arbitru',
    dragonulCards: 'Membri / Carduri / Carduri Dragonul',
  },
  athletesCards: 'Carduri Sportiv',
  birthDate: 'Data Nașterii',
  cardIdDate: 'Data Legitimării',
  cards: 'Carduri',
  cardsReport: 'Raport_Carduri',
  category: 'Categorie',
  categoryDate: 'Data Categoriei',
  club: 'Club',
  coach: 'Antrenor',
  coachesCards: 'Carduri Antrenor',
  createdAt: 'Creat La',
  date: 'Data',
  dateType: 'Tip Dată',
  degree: 'Grad',
  dragonulCards: 'Carduri Dragonul',
  fee: 'Taxă',
  firstname: 'Prenume',
  instructor: 'Instructor',
  instructorsCards: 'Carduri Instructor',
  issueDate: 'Data Legitimării',
  lastname: 'Nume Familie',
  memberId: 'ID Membru',
  memberName: 'Nume Membru',
  noCardIdDate: 'Membrul selectat nu are dată de legitimare. Cardul nu se salvează.',
  noPhoto: 'Membrul selectat nu are foto. Cardul nu se salvează',
  operand: 'Operand',
  serial: 'Serie și Nr.',
  sportsman: 'Sportiv',
  umpire: 'Arbitru',
  umpiresCards: 'Carduri Arbitru',
}