/**
 * Settings Module
 */
import {
  sidebarFilters,
  routerAnimations,
  themes,
  headerFilters
} from "./data";
import languages from 'src/locales/languages';

const state = {
  darkMode: false,                                       // dark mode
  collapseSidebar: false,                                // mini sidevar
  rtlLayout: false,                                      // rtl layout
  backgroundImage: false,                                // enable sidebar background image
  horizontalLayoutSidebar: false,                        // horizontal layout sidebar
  languages,                                             // languages
  selectedLocale: JSON.parse(localStorage.getItem('selectedLocale')) || languages[0], // selected locale
  sidebarFilters,                                        // sidebar filters
  sidebarSelectedFilter: sidebarFilters[0],              // selected sidebar filter
  routerAnimations,                                      // router animations
  selectedRouterAnimation: routerAnimations[0],          // selected router animation
  themes,                                                // themes
  selectedTheme: themes[0],                              // selected theme
  headerFilters,                                         // header filters
  activeHeaderFilter: headerFilters[1],                  // selected header filter
  mobileSearchForm: false								   // Is Mobile Search Form Open
};

// getters
const getters = {
  darkMode: state => state.darkMode,
  collapseSidebar: state => state.collapseSidebar,
  boxLayout: state => state.boxLayout,
  rtlLayout: state => state.rtlLayout,
  backgroundImage: state => state.backgroundImage,
  selectedLocale: state => state.selectedLocale,
  languages: state => state.languages,
  selectedSidebarBgImage: state => state.selectedSidebarBgImage,
  sidebarFilters: state => state.sidebarFilters,
  sidebarSelectedFilter: state => state.sidebarSelectedFilter,
  selectedRouterAnimation: state => state.selectedRouterAnimation,
  routerAnimations: state => state.routerAnimations,
  themes: state => state.themes,
  selectedTheme: state => state.selectedTheme,
  headerFilters: state => state.headerFilters,
  activeHeaderFilter: state => state.activeHeaderFilter,
  horizontalLayoutSidebar: state => state.horizontalLayoutSidebar,
  mobileSearchForm: state => state.mobileSearchForm
};

// actions
const actions = {
  darkModeHandler (context) {
    context.commit('darkModeHandler');
  },
  collapseSidebar (context) {
    context.commit('collapseSidebarHandler');
  },
  boxLayout (context) {
    context.commit('boxLayoutHandler');
  },
  rtlLayout (context) {
    context.commit('rtlLayoutHandler');
  },
  backgroundImage (context) {
    context.commit('sidebarBgImageHandler');
  },
  changeLanguage (context, payload) {
    context.commit('changeLanguageHandler', payload);
  },
  changeBackgroundImage (context, payload) {
    context.commit('changeBackgroundImageHandler', payload);
  },
  changeSidebarFilter (context, payload) {
    context.commit('changeSidebarFilterHandler', payload);
  },
  changeRouterAnimation (context, payload) {
    context.commit('changeRouterAnimationHandler', payload);
  },
  changeTheme (context, payload) {
    context.commit('changeThemeHandler', payload);
  },
  changeHeaderFilter (context, payload) {
    context.commit('changeHeaderFilterHandler', payload);
  },
  setMiniSidebarLayout (context, payload) {
    context.commit('setMiniSidebarLayoutHandler', payload);
  },
  toggleHorizontalLayoutSidebar (context, payload) {
    context.commit('toggleHorizontalLayoutSidebarHandler', payload);
  },
  toggleSearchForm (context) {
    context.commit('toggleSearchFormHandler');
  }
};

// mutations
const mutations = {
  darkModeHandler (state) {
    state.darkMode = !state.darkMode;
  },
  collapseSidebarHandler (state) {
    state.collapseSidebar = !state.collapseSidebar;
  },
  boxLayoutHandler (state) {
    state.boxLayout = !state.boxLayout;
  },
  rtlLayoutHandler (state) {
    state.rtlLayout = !state.rtlLayout;
  },
  sidebarBgImageHandler (state) {
    state.backgroundImage = !state.backgroundImage;
  },
  changeLanguageHandler (state, language) {
    state.selectedLocale = language;
    localStorage.setItem('selectedLocale', JSON.stringify(language));
  },
  changeBackgroundImageHandler (state, image) {
    state.selectedSidebarBgImage = image;
  },
  changeSidebarFilterHandler (state, filter) {
    state.sidebarSelectedFilter = filter;
  },
  changeRouterAnimationHandler (state, animation) {
    state.selectedRouterAnimation = animation;
  },
  changeThemeHandler (state, theme) {
    state.selectedTheme = theme;
  },
  changeHeaderFilterHandler (state, filter) {
    state.activeHeaderFilter = filter;
  },
  setMiniSidebarLayoutHandler (state, isSet) {
    state.collapseSidebar = isSet;
  },
  toggleHorizontalLayoutSidebarHandler (state, value) {
    state.horizontalLayoutSidebar = value;
  },
  toggleSearchFormHandler (state) {
    state.mobileSearchForm = !state.mobileSearchForm;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
}
