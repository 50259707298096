import { isGranted } from "@/mixins/helpers";

export default {
  path: "/",
  component: () => import("@/components/HorizontalLayout"),
  redirect: "/dashboard",
  children: [
    {
      component: () => import("@/views/dashboard/Dashboard"),
      path: "/dashboard",
      meta: {
        requiresAuth: true,
        title: "message.global.dashboard",
        breadcrumb: "message.global.breadcrumb.dashboard",
      },
    },
    {
      component: () => import("@/views/users/UserProfile"),
      path: "/users/user-profile",
      meta: {
        requiresAuth: true,
        title: "message.users.userProfile",
        breadcrumb: "message.users.breadcrumb.usersUserProfile",
      },
    },
    {
      component: () => import("@/views/users/UsersList"),
      path: "/users/users",
      meta: {
        requiresAuth: true,
        title: "message.users.usersList",
        breadcrumb: "message.users.breadcrumb.usersUsersList",
      },
      beforeEnter: (to, from, next) => {
        if (isGranted(["read:users"])) {
          next();
        } else {
          next({ path: "/" });
        }
      },
    },
    {
      component: () => import("@/views/settings/Clubs"),
      path: "/settings/clubs",
      meta: {
        requiresAuth: true,
        title: "message.clubs.clubsList",
        breadcrumb: "message.clubs.breadcrumb.clubsClubsList",
      },
      beforeEnter: (to, from, next) => {
        if (isGranted(["read:clubs"])) {
          next();
        } else {
          next({ path: "/" });
        }
      },
    },
    {
      component: () => import("@/views/settings/Degrees"),
      path: "/settings/degrees",
      meta: {
        requiresAuth: true,
        title: "message.degrees.degreesList",
        breadcrumb: "message.degrees.breadcrumb.degreesDegreesList",
      },
    },
    {
      component: () => import("@/views/registry/Registries"),
      path: "/registry/records",
      meta: {
        requiresAuth: true,
        title: "message.registry.documentList",
        breadcrumb: "message.registry.breadcrumb.registryDocumentList",
      },
      beforeEnter: (to, from, next) => {
        if (isGranted(["read:registries"])) {
          next();
        } else {
          next({ path: "/" });
        }
      },
    },
    {
      component: () => import("@/views/members/athletes/Athletes"),
      path: "/members/athletes",
      meta: {
        requiresAuth: true,
        title: "message.members.athletes",
        breadcrumb: "message.members.breadcrumb.athletes",
      },
    },
    {
      component: () => import("@/views/members/athletes/Archive"),
      path: "/members/archive",
      meta: {
        requiresAuth: true,
        title: "message.members.archive",
        breadcrumb: "message.members.breadcrumb.archive",
      },
    },
    {
      component: () => import("@/views/members/athletes/Athlete"),
      path: "/members/athletes/:id",
      name: "athlete",
      props: true,
      meta: {
        requiresAuth: true,
        title: "message.members.athlete",
        breadcrumb: "message.members.breadcrumb.athlete",
      },
    },
    {
      component: () => import("@/views/members/instructors/Instructors"),
      path: "/members/instructors",
      meta: {
        requiresAuth: true,
        title: "message.members.instructors",
        breadcrumb: "message.members.breadcrumb.instructors",
      },
    },
    {
      component: () => import("@/views/members/umpires/Umpires"),
      path: "/members/umpires",
      meta: {
        requiresAuth: true,
        title: "message.members.umpires",
        breadcrumb: "message.members.breadcrumb.umpires",
      },
    },
    {
      component: () => import("@/views/members/visas/AthletesVisas"),
      path: "/members/visas/athletes",
      meta: {
        requiresAuth: true,
        title: "message.visas.athletesVisas",
        breadcrumb: "message.visas.breadcrumb.athletesVisas",
      },
    },
    {
      component: () => import("@/views/members/visas/CoachesVisas"),
      path: "/members/visas/coaches",
      meta: {
        requiresAuth: true,
        title: "message.visas.coachesVisas",
        breadcrumb: "message.visas.breadcrumb.coachesVisas",
      },
    },
    {
      component: () => import("@/views/members/visas/InstructorsVisas"),
      path: "/members/visas/instructors",
      meta: {
        requiresAuth: true,
        title: "message.visas.instructorsVisas",
        breadcrumb: "message.visas.breadcrumb.instructorsVisas",
      },
    },
    {
      component: () => import("@/views/members/visas/UmpiresVisas"),
      path: "/members/visas/umpires",
      meta: {
        requiresAuth: true,
        title: "message.visas.umpiresVisas",
        breadcrumb: "message.visas.breadcrumb.umpiresVisas",
      },
    },
    {
      component: () => import("@/views/members/cards/AthletesCards"),
      path: "/members/cards/athletes",
      meta: {
        requiresAuth: true,
        title: "message.cards.athletesCards",
        breadcrumb: "message.cards.breadcrumb.athletesCards",
      },
    },
    {
      component: () => import("@/views/members/cards/CoachesCards"),
      path: "/members/cards/coaches",
      meta: {
        requiresAuth: true,
        title: "message.cards.coachesCards",
        breadcrumb: "message.cards.breadcrumb.coachesCards",
      },
    },
    {
      component: () => import("@/views/members/cards/InstructorsCards"),
      path: "/members/cards/instructors",
      meta: {
        requiresAuth: true,
        title: "message.cards.instructorsCards",
        breadcrumb: "message.cards.breadcrumb.instructorsCards",
      },
    },
    {
      component: () => import("@/views/members/cards/UmpiresCards"),
      path: "/members/cards/umpires",
      meta: {
        requiresAuth: true,
        title: "message.cards.umpiresCards",
        breadcrumb: "message.cards.breadcrumb.umpiresCards",
      },
    },
    {
      component: () => import("@/views/members/cards/DragonulCards"),
      path: "/members/cards/dragonul",
      meta: {
        requiresAuth: true,
        title: "message.cards.dragonulCards",
        breadcrumb: "message.cards.breadcrumb.dragonulCards",
      },
      beforeEnter: (to, from, next) => {
        if (isGranted(["read:dragonulcards"])) {
          next();
        } else {
          next({ path: "/" });
        }
      },
    },
    {
      component: () => import("@/views/events/seminars/Seminars"),
      path: "/events/seminars",
      meta: {
        requiresAuth: true,
        title: "message.events.seminars",
        breadcrumb: "message.events.breadcrumb.seminars",
      },
    },
    {
      component: () => import("@/views/events/seminars/Seminar"),
      path: "/events/seminars/:id",
      name: "seminar",
      props: true,
      meta: {
        requiresAuth: true,
        title: "message.events.seminar",
        breadcrumb: "message.events.breadcrumb.seminar",
      },
    },
    {
      component: () => import("@/views/events/competitions/Competitions"),
      path: "/events/competitions",
      meta: {
        requiresAuth: true,
        title: "message.events.competitions",
        breadcrumb: "message.events.breadcrumb.competitions",
      },
    },
    {
      component: () => import("@/views/events/competitions/Competition"),
      path: "/events/competitions/:id",
      name: "competition",
      props: true,
      meta: {
        requiresAuth: true,
        title: "message.events.competition",
        breadcrumb: "message.events.breadcrumb.competition",
      },
    },
    {
      component: () => import("@/views/belts/Exams"),
      path: "/belts/exams",
      meta: {
        requiresAuth: true,
        title: "message.belts.exams",
        breadcrumb: "message.belts.breadcrumb.exams",
      },
    },
    {
      component: () => import("@/views/belts/Exam"),
      path: "/belts/exams/:id",
      name: "exam",
      props: true,
      meta: {
        requiresAuth: true,
        title: "message.belts.exam",
        breadcrumb: "message.belts.breadcrumb.exam",
      },
    },
    {
      component: () => import("@/views/belts/Belts"),
      path: "/belts/belts",
      meta: {
        requiresAuth: true,
        title: "message.belts.belts",
        breadcrumb: "message.belts.breadcrumb.belts",
      },
    },
    {
      component: () => import("@/views/belts/Forecasts"),
      path: "/belts/forecasts",
      meta: {
        requiresAuth: true,
        title: "message.belts.forecasts",
        breadcrumb: "message.belts.breadcrumb.forecasts",
      },
    },
  ],
};
