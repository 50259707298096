<template>
  <div :class="[colClasses, { 'flex': colClasses}]">
    <fullscreen ref="fullscreen">
      <div class="app-card" :class="[customClasses, { 'd-none': close }]">
        <app-card-heading
            :heading="heading"
            :closeable="closeable"
            :with-settings="withSettings"
            :reloadable="reloadable"
            :fullScreen="fullScreen"
            :withTabs="withTabs"
            :tabs="tabs"
            @onChangeTabCallback="onChangeTab"
            @onCollapse="onCollapse"
            @onReload="onReload"
            @onClose="onClose"
            @onSettings="onSettings"
        >
        </app-card-heading>
        <app-card-content :extraClass="[contentCustomClass]" :fullBlock="fullBlock">
          <slot/>
        </app-card-content>
        <app-card-footer v-if="footer">
          <slot name="footer"/>
        </app-card-footer>
        <app-section-loader :status="reload"/>
      </div>
    </fullscreen>
  </div>
</template>

<script>
import AppCardHeading from "./AppCardHeading";
import AppCardFooter from "./AppCardFooter";
import AppCardContent from "./AppCardContent";
import AppSectionLoader from "./AppSectionLoader";

export default {
  props: [
    "heading",
    "colClasses",
    "customClasses",
    "fullBlock",
    "contentCustomClass",
    "closeable",
    "withSettings",
    "reloadable",
    "fullScreen",
    "footer",
    "withTabs",
    "tabs",
    "onChangeTabCallback"
  ],
  data: () => ({
    reload: false,
    close: false
  }),
  components: {
    AppCardHeading,
    AppCardFooter,
    AppCardContent,
    AppSectionLoader
  },
  methods: {
    onCollapse () {
      this.$refs["fullscreen"].toggle();
    },
    onReload () {
      this.reload = true;
      this.$emit('onReload');
    },
    onClose () {
      this.close = true;
    },
    onChangeTab (value) {
    },
    onSettings (value) {
      this.$emit("onSettings", value);
    }
  }
};
</script>
