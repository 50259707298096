export default {
  breadcrumb:{
    athletesVisas: 'Membri / Vize anuale / Vize de sportiv',
    coachesVisas: 'Membri / Vize anuale / Vize de antrenor',
    instructorsVisas: 'Membri / Vize anuale / Vize de instructor',
    umpiresVisas: 'Membri / Vize anuale / Vize de arbitru',
  },
  active: 'Active',
  annualVisas: 'Vize anuale',
  athleteReport: 'Raport_vize_sportiv_',
  athletesVisas: 'Vize de sportiv',
  birthDate: 'Data nașterii',
  club: 'Club',
  coacheReport: 'Raport_vize_antrenor_',
  coachesVisas: 'Vize de antrenor',
  createdAt: 'Creată la',
  degree: 'Grad',
  endDate: 'Dată sfârșit',
  fee: 'Taxă',
  instructorReport: 'Raport_vize_instructor_',
  instructorsVisas: 'Vize de instructor',
  memberId: 'Legitimație',
  memberName: 'Nume membru',
  serial: 'Serie viză',
  startDate: 'Dată start',
  umpireReport: 'Raport_vize_arbitru_',
  umpiresVisas: 'Vize de arbitru',
}
