export default {
  buttons: {
    addRecord: 'Adaugă înregistrare',
    clearFilters: 'Anulează filtrarea',
    deleteRecord: 'Șterge înregistrarea',
    editRecord: 'Modifică înregistrarea',
    exportToExcel: 'Exportă în Excel',
    generateCards: 'Generează legitimații',
    generateDragonulCards: 'Generează legitimații Dragonul',
    refreshTable: 'Regenerează tabelul',
    viewPdf: 'Vezi ca PDF',
    viewRecord: 'Vizualizează înregistrarea',
  },
  fields: {
    actions: 'Acțiuni'
  },
  paginator: {
    found: 'găsite',
    of: 'din',
  },
  from: 'De la',
  noDataText: 'Nu există date disponibile',
  noResultsText: 'Nu s-a găsit nici o înregistrare care să se potrivească',
  rowsPerPage: "Linii pe pagină",
  to: 'la',

}
