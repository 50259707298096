export default {
  breadcrumb: {
    dashboard: 'Tablou de bord'
  },
  add: 'Adaugă',
  advance: 'Avansat',
  advanced: 'Avansat',
  alert: 'Alertă',
  all: 'Toate',
  applyFilter: 'Aplică filtre',
  areYouSureHeading: 'Ești sigur că vrei să ștergi?',
  areYouSureMessage: 'Ești sigur că vrei să ștergi permanent această înregistrare?',
  attention: 'Atenție',
  boardMember: 'Membru în Biroul Federal',
  cancel: 'Renunță',
  cannotRemove: 'Înregistrarea nu poate fi eliminată. Există elemente care depind de ea.',
  close: 'Închide',
  confirm: 'Confirmă',
  creationTime: 'Creat',
  darkMode: 'Mod întunecat',
  dashboard: 'Tablou de bord',
  dashboardOverview: 'Prezentarea panoului de bord',
  dropFilesToUpload: 'Trage fișier pentru încărcare',
  duplicateEntry: 'Înregistrarea există deja - nu se salvează',
  edit: 'Modifică',
  errorOccurred: 'S-a produs o eroare',
  federalSecretary: 'Secretar Federal',
  female: 'Feminin',
  filterOptions: 'Opțiuni de căutare',
  filtering: 'Filtrare',
  fullScreen: 'Vezi pe tot ecranul',
  general: 'General',
  generalSecretary: 'Secretar General',
  headerFilters: 'Filtre bară superioară',
  languages: "Limbi",
  loadMore: 'Încarcă mai multe',
  logOut: 'Deconectare',
  male: 'Masculin',
  new: 'Nou',
  next: 'Pasul Următor',
  no: 'Nu',
  noItemsSelected: 'Nu ai selectat nici o înregistrare',
  overview: 'General',
  photoDeleted: 'Foto eliminată cu succes',
  photoUpdated: 'Imagine actualizată cu succes',
  president: 'Președinte',
  prev: 'Pasul Precedent',
  recordAdded: 'Înregistrare adăugată cu succes',
  recordDeleted: 'Înregistrare eliminată cu succes',
  recordSaved: 'Înregistrare salvată cu succes',
  recordUpdated: 'Înregistrare actualizată cu succes',
  reload: 'Reîncarcă',
  report: 'Raport',
  resetFilter: 'Elimină Filtre',
  rotate: 'Rotește',
  routerAnimation: 'Animație schimbare pagină',
  rtlLayout: 'Afișare Rtl',
  save: 'Salvează',
  search: 'Căutare',
  selectAnimation: 'Selectare animație',
  selectTheme: 'Selectare temă',
  settings: 'Parametri',
  technicalDirector: 'Director Tehnic',
  themeOptions: 'Opțiunile temei',
  type: 'Tip',
  unauthorized: 'Credențialele au expirat. Te rog să te reconectezi',
  uploadImage: 'Încarcă o imagine',
  vicePresident: 'Vicepreședinte',
  yes: 'Da',
}
