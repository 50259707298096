export default {
  breadcrumb: {
    dashboard: 'Dashoard'
  },
  add: 'Add',
  advance: 'Advance',
  advanced: 'Advanced',
  alert: 'Alert',
  all: 'All',
  applyFilter: 'Apply',
  areYouSureHeading: 'Are you sure you want to delete?',
  areYouSureMessage: 'Are you sure you want to permanently delete this record?',
  attention: 'Attention',
  boardMember: 'Board Member',
  cancel: 'Cancel',
  cannotRemove: 'Record cannot be removed. Other data depends on it.',
  close: 'Close',
  confirm: 'Confirm',
  creationTime: 'Creation Time',
  darkMode: 'Dark Mode',
  dashboard: 'Dashboard',
  dashboardOverview: 'Dashboard Overview',
  dropFilesToUpload: 'Drop files to upload',
  duplicateEntry: 'This entry already exists - won\'t save',
  edit: 'Edit',
  errorOccurred: 'An error occurred',
  federalSecretary: 'Federal Secretary',
  female: 'Female',
  filterOptions: 'Filter Options',
  filtering: 'Filtering',
  fullScreen: 'Full Screen',
  general: 'General',
  generalSecretary: 'General Secretary',
  headerFilters: 'Header Filters',
  languages: "Languages",
  loadMore: 'Load More',
  logOut: 'Log Out',
  male: 'Male',
  new: 'New',
  next: 'Next Step',
  no: 'No',
  noItemsSelected: 'No Items Selected',
  overview: 'Overview',
  photoDeleted: 'Photo successfully deleted',
  photoUpdated: 'Photo successfully updated',
  president: 'President',
  prev: 'Previous Step',
  recordAdded: 'Record successfully added',
  recordDeleted: 'Record successfully deleted',
  recordSaved: 'Record successfully saved',
  recordUpdated: 'Record successfully updated',
  reload: 'Reload',
  report: 'Report',
  resetFilter: 'Reset',
  rotate: 'Rotate',
  routerAnimation: 'Router Animation',
  rtlLayout: 'Rtl Layout',
  save: 'Save',
  search: 'Search',
  selectAnimation: 'Select Animation',
  selectTheme: 'Select Theme',
  settings: 'Settings',
  technicalDirector: 'Technical Director',
  themeOptions: 'Theme Options',
  type: 'Type',
  unauthorized: 'Credentials expired. Please login again',
  uploadImage: 'Upload an image',
  vicePresident: 'Vice President',
  yes: 'Yes',
}
