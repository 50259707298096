export default {
  atDate: 'At Date',
  breadcrumb: {
    annualLicenseList: 'Members / Annual Licenses',
  },
  annualLicenseList: 'Annual Licenses',
  club: 'Club',
  coach: 'Coach',
  endDate: 'End Date',
  fee: 'Fee',
  filterByClub: 'Filter By Club',
  instructor: 'Instructor',
  inTermLabel: 'In Term',
  inTermTooltip: 'Filter within its validity period',
  outdatedLabel: 'Outdated',
  outdatedTooltip: 'Filter outdated licenses',
  serial: 'Serial No',
  sportsman: 'Sportsman',
  sportsmanName: 'Sportsman Name',
  startDate: 'Start Date',
  type: 'Type',
  umpire: 'Umpire',
  visaReport: 'Visa_Report',
}