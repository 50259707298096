/**
 * AUTH module state
 */

export default {
  accessToken: localStorage.getItem('accessToken'),
  expiresAt: parseInt(localStorage.getItem('expiresAt')),
  idToken: localStorage.getItem('idToken'),
  permissions: JSON.parse(localStorage.getItem('permissions')) || [],
  user: JSON.parse(localStorage.getItem('user')) || {},
  userClub: JSON.parse(localStorage.getItem('userClub')) || '',
  userMeta: JSON.parse(localStorage.getItem('userMeta')) || {},
}