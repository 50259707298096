<template>
  <v-container fluid>
    <app-card :fullBlock="true">
      <template>
        <v-breadcrumbs :items="$breadcrumbs">
          <template v-slot:item="props">
            <h2 class="text-capitalize mb-0">{{$t(pageTitle)}}</h2>
            <div class="spacer"></div>
            <v-breadcrumbs-item class="no-print">{{$t(pageBreadcrumb)}}</v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </template>
    </app-card>
  </v-container>
</template>

<script>
  export default {
    computed: {
      // computed property to get page breadcrumbs
      pageTitle () {
        return this.$breadcrumbs[0].meta.title;
      },
      pageBreadcrumb () {
        return this.$breadcrumbs[0].meta.breadcrumb;
      }
    }
  };
</script>
