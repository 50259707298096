/**
 * App Config File
 */

export default {
  appLogo2: "/static/img/frtkd-logo.png", // App Logo 2 For Login & Signup Page
  appLogo: "/static/img/site-frtkd-logo.png", // App Logo,
  baseUrl:
    process.env.NODE_ENV === "production"
      ? "https://api.taekwondo-itf.ro/"
      : "http://localhost:3010/",
  brand: "FRTKD - Online", // Brand Name
};
