export default {
  breadcrumb: {
    athlete: "Membri / Sportivi / Sportiv",
    athletes: "Membri / Sportivi",
    archive: "Membri / Arhivă",
    instructors: "Membri / Instructori",
    umpires: "Membri / Arbitri",
  },
  active: "Activ",
  activeUntil: "Activ Până La",
  affiliated: "Legitimat",
  annualAthleteVisa: "Viză Anuală de Sportiv",
  annualInstructorLicense: "Licență Anuală de Instructor",
  annualUmpireLicense: "Licență Anuală de Arbitru",
  archive: "Arhivă",
  athlete: "Sportiv",
  athletes: "Sportivi",
  beltHistory: "Istoric Grade",
  birthDate: "Data Nașterii",
  birthdate: "Data Nașterii",
  cardId: "Legitimație",
  cardIdDate: "Data Legitimării",
  category: "Categorie",
  categoryDate: "Dată Categorie",
  club: "Club",
  coachId: "ID Instructor",
  degree: "Grad",
  degreeDate: "Dată Grad",
  degreeNumber: "Număr Grad",
  detailsView: "Vezi detalii",
  firstname: "Prenume",
  gender: "Sex",
  hasCnp: "Are CNP",
  hasGdpr: "Are GDPR",
  hasId: "Are ID",
  hasNotCnp: "Nu are CNP",
  hasNotGdpr: "Nu are GDPR",
  hasNotId: "Nu are ID",
  idDocument: "Document de Identitate",
  idNumberDate: "Data Legitimării",
  inactive: "Inactiv",
  instructor: "Instructor",
  instructorCategory: "Categorie Instructor",
  instructorCategoryDate: "Dată Cat. Instr.",
  instructorEndDate: "Dată Exp. Lic. Instr.",
  instructorId: "ID Instructor",
  instructors: "Instructori",
  itfCode: "Cod ITF",
  lastname: "Nume (de familie)",
  member: "Membru",
  memberId: "ID Sportiv",
  memberName: "Nume membru",
  members: "Membri",
  membersReport: "Raport",
  noPhoto: "Fără foto",
  notAffiliated: "Nelegitimat",
  notFound: "Nu sunt date cu această căutare",
  onlyPhoto: "Cu foto",
  patternPoints: "Puncte TULL",
  personalData: "Date Personale",
  photo: "Foto",
  powerPoints: "Puncte SPARGERI FORȚĂ",
  reviewAndSave: "Verificare și Salvare",
  seminarHistory: "Istoric Stagii",
  sparringPoints: "Puncte LUPTĂ",
  specialPoints: "Puncte TEHNICI SPECIALE",
  sportsmanName: "Nume sportiv",
  status: "Stare",
  umpire: "Arbitru",
  umpireCategory: "Categorie Arbitru",
  umpireCategoryDate: "Dată Categorie Arbitru",
  umpireEndDate: "Dată Expirare Lic. Arbitru",
  umpireId: "ID Arbitru",
  umpires: "Arbitri",
  withoutCard: "Fără card emis",
};
