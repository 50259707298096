export default {
  ER_DUP_ENTRY: 'Record already exists in the DB.',
  ER_ROW_IS_REFERENCED_2: 'Record has references in the DB and cannot be deleted.',
  access_forbidden: 'Access forbidden',
  code404: 'Request failed with status code 404',
  errorOccurred: 'An error occurred',
  invoice_not_issued: 'Invoice not issued in SmartBill',
  noItemsSelected: 'No Items Selected',
  photoAndActiveRequired: 'Photo is required and member must be active',
  record_cannot_be_deleted: 'This record cannot be deleted',
  record_forbidden: 'You don\'t have enough permissions so see this record',
}