/**
 * FRTKD Module
 */

import api from "src/config/api";
import api2 from "src/config/api2";
import Vue from "vue";
import i18n from "src/locales";

const state = {
  waitingProcesses: 0,
  degrees: [],
  clubs: [],
  categories: [],
  errorMessages: {
    noItemsSelected: () => i18n.t("message.errors.noItemsSelected"),
    "Request failed with status code 404": () =>
      i18n.t("message.errors.code404"),
    ER_ROW_IS_REFERENCED_2: () =>
      i18n.t("message.errors.ER_ROW_IS_REFERENCED_2"),
    ER_DUP_ENTRY: () => i18n.t("message.errors.ER_DUP_ENTRY"),
    invoice_not_issued: () => i18n.t("message.errors.invoice_not_issued"),
    record_forbidden: () => i18n.t("message.errors.record_forbidden"),
    record_cannot_be_deleted: () =>
      i18n.t("message.errors.record_cannot_be_deleted"),
    access_forbidden: () => i18n.t("message.errors.access_forbidden"),
    default: () => i18n.t("message.global.errorOccurred"),
  },
};

// getters
const getters = {
  waitingProcesses: (state) => state.waitingProcesses,

  degrees: (state) => state.degrees,

  clubs: (state) => state.clubs,

  errorMessages: (state) => state.errorMessages,
};

// mutations
const mutations = {
  incrementWaitingProcesses: (state) => state.waitingProcesses++,

  decrementWaitingProcesses: (state) => {
    if (state.waitingProcesses > 1) {
      state.waitingProcesses--;
    } else {
      setTimeout(() => {
        state.waitingProcesses = 0;
      }, 1000);
    }
  },

  cancelWaitingProcesses: (state) => {
    setTimeout(() => {
      state.waitingProcesses = 0;
    }, 1000);
  },

  setDegrees: (state, degrees) => (state.degrees = degrees),

  setClubs: (state, clubs) => (state.clubs = clubs),
};

// actions
const actions = {
  incrementWaitingProcesses: (context) =>
    context.commit("incrementWaitingProcesses"),

  decrementWaitingProcesses: (context) =>
    context.commit("decrementWaitingProcesses"),

  cancelWaitingProcesses: (context) => context.commit("cancelWaitingProcesses"),

  async getDegrees({ commit, dispatch, getters }) {
    commit("incrementWaitingProcesses");

    try {
      const response = await api.get("/degrees", {
        headers: getters.requestHeaders,
      });

      // Ensure the data and degrees are defined
      if (response && response.data && response.data.records) {
        commit("setDegrees", response.data.records);
      } else {
        console.error("Unexpected API response format:", response);
        dispatch("triggerError", "Unexpected API response format.");
      }
    } catch (error) {
      // Handle cases where error.response or error.response.data might be undefined
      const errorMessage =
        error.response && error.response.data
          ? error.response.data
          : "An unexpected error occurred.";
      dispatch("triggerError", errorMessage);
    } finally {
      commit("decrementWaitingProcesses");
    }
  },

  getClubs({ commit, dispatch, rootState }) {
    commit("incrementWaitingProcesses");
    let promise;
    if (rootState.auth.permissions.includes("read:clubs")) {
      promise = api.get("/clubs", { headers: this.getters.requestHeaders });
    } else {
      promise = api.get("/clubs/myclub", {
        headers: this.getters.requestHeaders,
      });
    }
    promise
      .then((r) => {
        commit("setClubs", r.data);
        commit("decrementWaitingProcesses");
      })
      .catch((e) => dispatch("triggerError", e.response.data));
  },

  triggerError: (context, payload) => {
    let key = "default";

    if (payload && payload.hasOwnProperty("message")) key = payload.message;

    if (key === "default") console.error(JSON.stringify(payload));
    context.commit("cancelWaitingProcesses");
    Vue.notify({
      group: "loggedIn",
      type: "error",
      duration: 5000,
      text: (
        context.getters.errorMessages[key] ||
        context.getters.errorMessages["default"]
      )(),
    });
  },

  successNotification: (context, payload) =>
    Vue.notify({
      text: payload,
      group: "loggedIn",
      type: "success",
      duration: 5000,
    }),

  warningNotification: (context, payload) =>
    Vue.notify({
      text: payload,
      group: "loggedIn",
      type: "warning",
      duration: 5000,
    }),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
