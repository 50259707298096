export default {
  ER_DUP_ENTRY: 'Înregistrarea există deja în baza de date.',
  ER_ROW_IS_REFERENCED_2: 'Înregistrarea are referințe în baza de date și nu poate fi ștearsă.',
  access_forbidden: 'Acces nepermis',
  code404: 'Cerere eșuată cu codul 404 - rută negăsită',
  errorOccurred: 'S-a produs o eroare',
  invoice_not_issued: 'Factura nu a fost încă emisă în SmartBill',
  noItemsSelected: 'Nu ai selectat nici o înregistrare',
  photoAndActiveRequired: 'Foto este necesară și membrul trebuie să fie activ',
  record_cannot_be_deleted: 'Înregistrarea nu poate fi ștearsă',
  record_forbidden: 'Nu ai permisiuni suficiente să vizualizezi această înregistrare',
}