export default {
  breadcrumb: {
    registryDocumentList: 'Registru / Corespondență',
  },
  content: 'Conținut',
  dispatchDate: 'Data expedierii',
  documentList: 'Corespondență',
  issuer: 'Emitent',
  number: 'Număr',
  recipient: 'Destinatar',
  recordDate: 'Dată înregistrare',
  recordIn: 'Intrare',
  recordNumber: 'Număr înregistrare',
  recordOut: 'Ieșire',
  registry: 'Registru',
  responseDate: 'Data răspunsului',
}