export default {
  atDate: 'La data',
  breadcrumb: {
    annualLicenseList: 'Membri / Licențe anuale',
  },
  annualLicenseList: 'Licențe anuale',
  club: 'Club',
  coach: 'Antrenor',
  endDate: 'Dată de final',
  fee: 'Taxă',
  filterByClub: 'Filtrează după club',
  instructor: 'Instructor',
  inTermLabel: 'În termen',
  inTermTooltip: 'Filtrează în termen de valabilitate',
  outdatedLabel: 'Expirate',
  outdatedTooltip: 'Filtrează licențele expirate',
  serial: 'Serie și Nr.',
  sportsman: 'Sportiv',
  sportsmanName: 'Nume sportiv',
  startDate: 'Dată de start',
  type: 'Tip',
  umpire: 'Arbitru',
  visaReport: 'Raport_Licente',
}