// Sidebar Routers

export const menus = {
  "message.global.dashboard": [
    {
      action: "zmdi-view-dashboard",
      title: "message.global.dashboard",
      path: "/dashboard",
      permissions: [],
      active: true,
      items: null,
    },
  ],
  "message.registry.registry": [
    {
      action: "zmdi-email",
      title: "message.registry.documentList",
      path: "/registry/records",
      permissions: ["read:registries"],
      active: false,
      items: null,
    },
  ],
  "message.members.members": [
    {
      action: "zmdi-accounts",
      title: "message.members.athletes",
      path: "/members/athletes",
      permissions: [],
      active: false,
      items: null,
    },
    {
      action: "zmdi-accounts",
      title: "message.members.archive",
      path: "/members/archive",
      permissions: [],
      active: false,
      items: null,
    },
    {
      action: "zmdi-accounts",
      title: "message.members.instructors",
      path: "/members/instructors",
      permissions: [],
      active: false,
      items: null,
    },
    {
      action: "zmdi-accounts",
      title: "message.members.umpires",
      path: "/members/umpires",
      permissions: [],
      active: false,
      items: null,
    },
    {
      action: "zmdi-file-text",
      title: "message.visas.annualVisas",
      path: "/members/visas",
      permissions: [],
      active: false,
      items: [
        {
          title: "message.visas.athletesVisas",
          path: "/members/visas/athletes",
          permissions: [],
        },
        {
          title: "message.visas.instructorsVisas",
          path: "/members/visas/instructors",
          permissions: [],
        },
        {
          title: "message.visas.coachesVisas",
          path: "/members/visas/coaches",
          permissions: [],
        },
        {
          title: "message.visas.umpiresVisas",
          path: "/members/visas/umpires",
          permissions: [],
        },
      ],
    },
    {
      action: "zmdi-card",
      title: "message.cards.cards",
      permissions: [],
      active: false,
      items: [
        {
          title: "message.cards.athletesCards",
          path: "/members/cards/athletes",
          permissions: [],
        },
        {
          title: "message.cards.coachesCards",
          path: "/members/cards/coaches",
          permissions: [],
        },
        {
          title: "message.cards.instructorsCards",
          path: "/members/cards/instructors",
          permissions: [],
        },
        {
          title: "message.cards.umpiresCards",
          path: "/members/cards/umpires",
          permissions: [],
        },
        {
          title: "message.cards.dragonulCards",
          path: "/members/cards/dragonul",
          permissions: ["read:dragonulcards"],
        },
      ],
    },
  ],
  "message.events.events": [
    {
      action: "ti-cup",
      title: "message.events.competitions",
      path: "/events/competitions",
      permissions: [],
      active: false,
      items: null,
    },
    {
      action: "ti-blackboard",
      title: "message.events.seminars",
      path: "/events/seminars",
      permissions: [],
      active: false,
      items: null,
    },
  ],
  "message.belts.belts": [
    {
      action: "ti-bar-chart",
      title: "message.belts.belts",
      path: "/belts/belts",
      permissions: [],
      active: false,
      items: null,
    },
    {
      action: "ti-bar-chart",
      title: "message.belts.forecasts",
      path: "/belts/forecasts",
      permissions: [],
      active: false,
      items: null,
    },
    {
      action: "ti-files",
      title: "message.belts.exams",
      path: "/belts/exams",
      permissions: [],
      active: false,
      items: null,
    },
  ],
  "message.global.settings": [
    {
      action: "zmdi-format-list-bulleted",
      title: "message.degrees.degrees",
      path: "/settings/degrees",
      permissions: [],
      active: false,
      items: null,
    },
    {
      action: "zmdi-accounts-list",
      title: "message.clubs.clubs",
      path: "/settings/clubs",
      permissions: ["read:clubs"],
      active: false,
      items: null,
    },
  ],
};
