export default {
  account: 'Account',
  active: 'Active',
  address: 'Address',
  bank: 'Bank',
  breadcrumb: {
    clubsClubsList: 'Clubs / Clubs List',
  },
  city: 'City',
  club: 'Club',
  clubName: 'Short Name',
  clubOfficialName: 'Official name',
  clubs: 'Clubs',
  clubShortTeam: 'Code',
  clubsList: 'Clubs List',
  country: 'Country',
  countryCode: 'Country Code',
  county: 'County',
  email: 'Email',
  inactive: 'Inactive',
  invoiceDelegate: 'Invoice Delegate',
  invoiceDelegateId: 'Invoice Delegate ID',
  phone: 'Phone',
  web: 'Web'
}