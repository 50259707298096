export default {
  breadcrumb:{
    athletesVisas: 'Members / Visas / Athletes Visas',
    coachesVisas: 'Members / Visas / Coaches Visas',
    instructorsVisas: 'Members / Visas / Instructors Visas',
    umpiresVisas: 'Members / Visas / Umpires Visas',
  },
  active: 'Active',
  annualVisas: 'Annual Visas',
  athleteReport: 'Report_visas_athletes_',
  athletesVisas: 'Athletes Visas',
  birthDate: 'Birth Date',
  club: 'Club',
  coachReport: 'Report_visas_coaches_',
  coachesVisas: 'Coaches Visas',
  createdAt: 'Created At',
  degree: 'Degree',
  endDate: 'End Date',
  fee: 'Fee',
  instructorReport: 'Report_visas_instructors_',
  instructorsVisas: 'Instructors Visas',
  memberId: 'Member ID',
  memberName: 'Member Name',
  serial: 'Serial No',
  startDate: 'Start Date',
  umpireReport: 'Report_visas_umpires_',
  umpiresVisas: 'Umpires Visas',
}