/**
 * AUTH module actions
 */

export default {
  logoutUserFromAuth0 (context) {
    context.commit('logoutUser')
  },

  signInUserWithAuth0 (context, payload) {
    context.commit('signInUserWithAuth0Success', payload);
  }
}