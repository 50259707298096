export default {
  breadcrumb: {
    athlete: "Members / Athletes / Athlete",
    athletes: "Members / Athletes",
    archive: "Members / Archive",
    instructors: "Members / Instructors",
    umpires: "Members / Umpires",
  },
  active: "Active",
  activeUntil: "Active Until",
  affiliated: "Affiliated",
  annualAthleteVisa: "Annual Athlete Visa",
  annualInstructorLicense: "Annual Instructor License",
  annualUmpireLicense: "Annual Umpire License",
  archive: "Archive",
  athlete: "Athlete",
  athletes: "Athletes",
  beltHistory: "Belt History",
  birthDate: "Birth Date",
  birthdate: "Birth Date",
  cardId: "Card ID",
  cardIdDate: "Card ID Date",
  category: "Category",
  categoryDate: "Category Date",
  club: "Club",
  coachId: "Instructor ID",
  degree: "Degree",
  degreeDate: "Degree Date",
  degreeNumber: "Degree Number",
  detailsView: "See Details",
  firstname: "First Name",
  gender: "Gender",
  hasCnp: "Has CNP",
  hasGdpr: "Has GDPR",
  hasId: "Has ID",
  hasNotCnp: "Has Not CNP",
  hasNotGdpr: "Has Not GDPR",
  hasNotId: "Has Not ID",
  idDocument: "Identity Document",
  idNumberDate: "ID Date",
  inactive: "Inactive",
  instructor: "Instructor",
  instructorCategory: "Instructor Category",
  instructorCategoryDate: "Instructor Category Date",
  instructorEndDate: "Instructor End Date",
  instructorId: "Instructor ID",
  instructors: "Instructors",
  itfCode: "ITF Code",
  lastname: "Last Name",
  member: "Member",
  memberId: "Athlete ID",
  members: "Members",
  membersReport: "Report",
  noPhoto: "Without Photo",
  notAffiliated: "Not Affiliated",
  notFound: "No Data Available For This Search",
  onlyPhoto: "With Photo",
  patternPoints: "Pattern Points",
  personalData: "Personal Data",
  photo: "Photo",
  powerPoints: "Power Breaking Points",
  reviewAndSave: "Review and Save",
  seminarHistory: "Seminar History",
  sparringPoints: "Sparring Points",
  specialPoints: "Special Techniques Points",
  sportsmanName: "Athlete Name",
  status: "Status",
  umpire: "Umpire",
  umpireCategory: "Umpire Category",
  umpireCategoryDate: "Umpire Category Date",
  umpireEndDate: "Umpire End Date",
  umpireId: "Umpire ID",
  umpires: "Umpires",
  withoutCard: "Without Card",
};
