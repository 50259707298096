import Vue from 'vue'
import moment from 'moment'
import store from 'src/store'
import AppConfig from 'src/config/app'

export const isGranted = permissions => {
  if (typeof permissions !== 'undefined' && permissions.length) {
    return permissions.some(permission => store.getters.permissions.includes(permission));
  }
  return true;
};

export default Vue.mixin({
  methods: {
    isGranted,

    capitalizeFirstLetter: str => str.charAt(0).toUpperCase() + str.slice(1),

    formatMyDate(date) {
      if (!date) return '';
      const [year, month, day] = date.substr(0, 10).split('-');
      return `${day}/${month}/${year}`
    },

    parseMyDate(date) {
      if (!date) return '';
      const [day, month, year] = date.includes('.') ? date.split('.') : date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },

    textTruncate(str, length, ending) {
      if (length == null) {
        length = 100;
      }
      if (ending == null) {
        ending = '...';
      }
      if (str.length > length) {
        return str.substring(0, length - ending.length) + ending;
      } else {
        return str;
      }
    },

    getTheDate(timestamp, format) {
      let time = timestamp * 1000;
      let formatDate = format ? format : 'MM-DD-YYYY';
      return moment(time).format(formatDate);
    },

    convertDateToTimeStamp(date, format) {
      let formatDate = format ? format : 'YYYY-MM-DD';
      return moment(date, formatDate).unix();
    },

    hexToRgbA(hex, alpha) {
      let c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length === 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255]
          .join(',') + ',' + alpha + ')';
      }
      throw new Error('Bad Hex');
    },

    getEndDateColor(date, today) {
      const diff = Math.floor((new Date(date) - new Date(today)) / (1000 * 60 * 60 * 24)); // in days
      if (diff > 30) {
        return 'success';
      } else if (diff > 0) {
        return 'warning';
      }
      return 'error';
    },

    moment() {
      return moment()
    },

    baseUrl() {
      return AppConfig.baseUrl;
    },

    getStatusColor(status) {
      const statuses = {
        'closed': () => 'default',
        'toPay': () => 'warning',
        'default': () => 'success'
      };
      return (statuses[status] || statuses['default'])();
    },

    blobPdfFromBase64String: base64String => {
      const byteArray = Uint8Array.from(atob(base64String).split('').map(char => char.charCodeAt(0)));
      return new Blob([byteArray], { type: 'application/pdf' });
    }
  }
})
