export default {
  breadcrumb: {
    registryDocumentList: 'Registry / Document List',
  },
  content: 'Content',
  dispatchDate: 'Dispatch Date',
  documentList: 'Document List',
  issuer: 'Issuer',
  number: 'Number',
  recipient: 'Recipient',
  recordDate: 'Record Date',
  recordIn: 'Incoming',
  recordNumber: 'Record Number',
  recordOut: 'Outgoing',
  registry: 'Registry',
  responseDate: 'Response Date',
}