export default {
  breadcrumb: {
    belts: 'Centuri / Centuri',
    candidates: 'Centuri / Candidați',
    exam: 'Centuri / Examen',
    exams: 'Centuri / Examene',
    forecasts: 'Centuri / Previziuni',
  },
  affiliationFee: 'Taxă Legitimare',
  affiliationFees: 'Taxe Legitimare',
  affiliations: 'Legitimări',
  age: 'Vârsta',
  annualFee: 'Viză Anuală',
  annualFees: 'Vize Anuale',
  belt: 'Centură',
  beltFees: 'Taxe Centuri',
  belts: 'Centuri',
  birthDate13: 'Împlinește 13 ani',
  birthDate: 'Data Nașterii',
  blackBeltNotPermitted: 'Centura neagră nu poate fi examinată',
  candidate: 'Candidat',
  candidates: 'Candidați',
  cannotExamineBlackBelt: 'Ne se poate examina centura neagră la club',
  city: 'Localitate',
  closed: 'Finalizat',
  club: 'Club',
  constraintsNotDefined: 'Cerințele sunt nedefinite',
  country: 'Țară',
  currentDegree: 'Grad Curent',
  degree: 'Grad',
  degreeDate: 'Dată Grad',
  desiredDate: 'Data dorită',
  documentIssued: 'Document creat cu succes. Îl poți descărca și efectua plata.',
  download: 'Descarcă',
  downloadCertificate: 'Descarcă Certificatul',
  downloadCertificates: 'Descarcă Toate Certificatele',
  downloadVisa: 'Descarcă Viza Anuală',
  eligible: 'Eligibil',
  eligibleNextSeminar: 'La următoarea participare',
  eligibleSeminars: 'Stagii Eligibile',
  eligibleSoon: 'Eligibil curând (+15z)',
  estimatedDate: 'Dată Estimată',
  estimatedDegree: 'Grad Estimat',
  estimatedDegreeDate: 'Data Grad Estimat',
  exam: 'Examen',
  examDate: 'Data Examenului',
  examStatus: 'Stare Examen',
  examiner: 'Examinator',
  examinerDegree: 'Grad Examinator',
  exams: 'Examene',
  fee: 'Taxă',
  forecasts: 'Previziuni',
  generate: 'Generează',
  invoice: 'Factura',
  lastSeminar: 'Ultimul stagiu',
  memberNotFound: 'Persoana nu a putut fi găsită. O putem adăuga...',
  minimumExamDateFor: 'Dată minimă pentru',
  missingGdpr: 'Lipsă formulare GDPR',
  missingId: 'Lipsă documente identitate',
  missingPhoto: 'Lipsă foto',
  name: 'Nume',
  newDegree: 'Grad Nou',
  noEligibleSeminars: 'Lipsă stagii',
  noValidationWarning: 'Puteți genera Proforma dar examenul nu poate fi validat până când nu este totul verde!',
  notEligible: 'Ne-eligibili',
  number: 'Număr de',
  oldDegree: 'Grad Vechi',
  open: 'Deschis',
  organizer: 'Organizator',
  periodNotSatisfied: 'Ultima examinare a avut loc în {0}. Perioada de așteptare este de {1} zile. Următoarea examinare posibilă începând cu {2}',
  photo: 'Foto',
  president: 'Președinte',
  presidentDegree: 'Grad Președinte',
  proforma: 'Proforma',
  proformaGenerationWarning: 'Această acțiune va genera Proforma și nu vei mai putea face modificări / adăugiri la examen. Ești sigur ca dorești să finalizezi examenul? Recomandăm cu insistență să efectuezi o ultimă verificare înainte de a continua cu plata.',
  qnsc: 'Stagiu Național',
  qnscExam: 'Stagiul Examenului',
  seminarNotSatisfied: 'Cerința de participare la stagiu nu este satisfăcută. Luați legatura cu secretariatul FRTKD',
  serial: 'Seria',
  status: 'Stare',
  technicalDir: 'Director Tehnic',
  technicalDirDegree: 'Grad Dir. Teh.',
  toBeExamined: 'La Examinare',
  toPay: 'De Plătit',
  today: 'Astăzi',
  total: 'Total',
  updateMember: 'Actualizează Sportiv',
  years: 'ani',
}
