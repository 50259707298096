export default {
  breadcrumb: {
    competitions: "Events / Competitions",
    seminar: "Events / Seminars / Seminar",
    seminars: "Events / Seminars",
  },
  aPerson: "A Person",
  activities: "Activities",
  activity: "Activity",
  addAthlete: "Add Athlete",
  addAttendant: "Add Attendant",
  addCoach: "Add Coach",
  addMember: "Add Member",
  addTeam: "Add Team",
  addUmpire: "Add Umpire",
  amounts: "Amounts",
  amountsByActivity: "Amounts By Activity",
  amountsByClub: "Amounts By Club",
  athleteSearch: "Athlete Search",
  attendantSearch: "Attendant Search",
  birthDate: "Birth Date",
  categories: "Categories",
  categAndComp: "Categories & Competitors (Individual)",
  children: "Children",
  city: "City",
  closed: "Closed",
  club: "Club",
  clubFees: "Club Fees",
  clubReports: "Club Reports",
  coachSearch: "Coach Search",
  coaches: "Coaches",
  competitions: "Competitions",
  competitorsInCategories: "Competitors In Categories",
  country: "Country",
  currency: "Currency",
  degree: "Degree",
  detailsView: "See Details",
  endDate: "End Date",
  enroll: "Enroll",
  errorNoGdpr: "The person must submit a signed GDPR form.",
  errorNoId: "The person must submit an ID for verification.",
  errorNoPhoto: "The person must have a photo",
  eurFee: "EUR Fee",
  eventName: "Event Name",
  events: "Events",
  federation: "Federation",
  federationReports: "Federation Reports",
  fee: "Fee",
  feeReport: "Fee Report",
  fees: "Fees",
  finalized: "Finalized",
  grandTotal: "Grand Total",
  juniors: "Juniors",
  latestAthleteVisa: "Latest Athlete Visa",
  latestInstructorVisa: "Latest Instructor Visa",
  latestUmpireVisa: "Latest Umpire Visa",
  maxDegree: "Max Degree",
  members: "Members",
  minDegree: "Min Degree",
  name: "Name",
  noClubSelected: "Please select a club to show this tab's content.",
  notifications: "Notifications",
  ongoing: "Ongoing",
  organizer: "Organizer",
  other: "Other",
  participants: "Participants",
  personName: "Person's Name",
  persons: "Persons",
  registeredByActivity: "Registered By Activity",
  registeredByClub: "Registered By Club",
  registration: "Registration",
  registrationAthletes: "Registration Athletes",
  registrationCoaches: "Registration Coaches",
  registrationEnd: "Last Registration Day",
  registrationTeams: "Registration Teams",
  registrationUmpires: "Registration Umpires",
  remove: "Remove",
  reports: "Reports",
  riskWaver: "Risk Waver",
  ronFee: "RON Fee",
  seminar: "Seminar",
  seminars: "Seminars",
  seniors: "Seniors",
  startDate: "Start Date",
  status: "Status",
  teamName: "Team Name",
  teamSearch: "Team Search",
  total: "Total",
  type: "Type",
  umpireSearch: "Umpire Search",
  umpires: "Umpires",
  visas: "Visas",
  warningNoPlaque:
    "The person has 4 Dan or more but has not selected the AETF instructor plaque.",
  warningNoRegistration: "The person is not registered in any activity.",
};
