import Vue from 'vue'
import Vuex from 'vuex'

// modules
import auth from './modules/auth';
import frtkd from './modules/frtkd';
import settings from './modules/settings';
import sidebar from './modules/sidebar';

const modules = {
  auth,
  frtkd,
  settings,
  sidebar,
};

Vue.use(Vuex);

export default new Vuex.Store({modules});
