export default {
  buttons: {
    addRecord: 'Add record',
    clearFilters: 'Clear filters',
    deleteRecord: 'Delete record',
    editRecord: 'Edit record',
    exportToExcel: 'Export to Excel',
    generateCards: 'Generate cards',
    generateDragonulCards: 'Generate Dragonul cards',
    refreshTable: 'Refresh table',
    viewPdf: 'View as PDF',
    viewRecord: 'View record',
  },
  fields: {
    actions: 'Actions'
  },
  paginator: {
    found: 'found',
    of: 'of',
  },
  from: 'From',
  noDataText: 'No data available',
  noResultsText: 'No matching records found',
  rowsPerPage: "Rows per page",
  to: 'to',
}
