export default {
  account: 'Cont',
  active: 'Activ',
  address: 'Adresă',
  bank: 'Banca',
  breadcrumb: {
    clubsClubsList: 'Cluburi / Lista cluburilor',
  },
  city: 'Localitate',
  club: 'Club',
  clubName: 'Denumire scurtă',
  clubOfficialName: 'Denumire oficială',
  clubs: 'Cluburi',
  clubShortTeam: 'Cod',
  clubsList: 'Lista cluburilor',
  country: 'Țară',
  countryCode: 'Cod țară',
  county: 'Județ',
  email: 'Email',
  inactive: 'Inactiv',
  invoiceDelegate: 'Delegat facturare',
  invoiceDelegateId: 'CI delegat facturare',
  phone: 'Telefon',
  web: 'Web'
}