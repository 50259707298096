export default {
  breadcrumb: {
    belts: 'Belts / Belts',
    candidates: 'Belts / Candidates',
    exams: 'Belts / Exams',
    exam: 'Belts / Exam',
    forecasts: 'Belts / Forecasts',
  },
  affiliationFee: 'Affiliation Fee',
  affiliationFees: 'Affiliation Fees',
  affiliations: 'Affiliations',
  age: 'Age',
  annualFee: 'Annual Fee',
  annualFees: 'Annual Fees',
  belt: 'Belt',
  beltFees: 'Belt Fees',
  belts: 'Belts',
  birthDate13: '13th Birthday',
  birthDate: 'Birth Date',
  blackBeltNotPermitted: 'Black Belt cannot be examined',
  candidate: 'Candidate',
  candidates: 'Candidates',
  cannotExamineBlackBelt: 'Black Belts cannot be examined in the club',
  city: 'City',
  closed: 'Closed',
  club: 'Club',
  constraintsNotDefined: 'Constraints Not Defined',
  country: 'Country',
  currentDegree: 'Current Degree',
  degree: 'Degree',
  degreeDate: 'Degree Date',
  desiredDate: 'Desired Date',
  documentIssued: 'Document successfully issued. You can download it and do the payment.',
  download: 'Download',
  downloadCertificate: 'Download Certificate',
  downloadCertificates: 'Download All Certificates',
  downloadVisa: 'Download Annual Visa Certificate',
  eligible: 'Eligible',
  eligibleNextSeminar: 'Within the Next Seminar',
  eligibleSeminars: 'Eligible Seminars',
  eligibleSoon: 'Eligible Soon (+15d)',
  estimatedDate: 'Estimated Date',
  estimatedDegree: 'Estimated Degree',
  estimatedDegreeDate: 'Estimated Degree Date',
  exam: 'Exam',
  examDate: 'Exam Date',
  examStatus: 'Exam Status',
  examiner: 'Examiner',
  examinerDegree: 'Examiner Degree',
  exams: 'Exams',
  fee: 'Fee',
  forecasts: 'Forecasts',
  generate: 'Generate',
  invoice: 'Invoice',
  lastSeminar: 'Last Seminar',
  memberNotFound: 'Member not found. You can add him / her...',
  minimumExamDateFor: 'Earliest Date For',
  missingGdpr: 'Missing GDPRs',
  missingId: 'Missing IDs',
  missingPhoto: 'Missing Photos',
  name: 'Name',
  newDegree: 'New Degree',
  noEligibleSeminars: 'No Seminars',
  noValidationWarning: 'You can still generate Proforma, but the Exam cannot be validated until everything is Green!',
  notEligible: 'Not Eligible',
  number: 'Number of',
  oldDegree: 'Old Degree',
  open: 'Open',
  organizer: 'Organizer',
  periodNotSatisfied: 'Latest examination was on {0}. Waiting period is {1} days. Next possible examination beginning with {2}',
  photo: 'Photo',
  president: 'President',
  presidentDegree: 'President Degree',
  proforma: 'Proforma',
  proformaGenerationWarning: 'This action will generate the Proforma and you won\'t be able to add or change persons in the Exam. Are you sure you want to finalize the exam? We strongly recommend you do a final check before you continue with the payment.',
  qnsc: 'National Seminar',
  qnscExam: 'Seminar of the Exam',
  seminarNotSatisfied: 'Seminar requirement not satisfied',
  serial: 'Serial',
  status: 'Status',
  technicalDir: 'Technical Director',
  technicalDirDegree: 'Tech. Dir. Degree',
  toBeExamined: 'To Be Examined',
  toPay: 'To Pay',
  today: 'Today',
  total: 'Total',
  updateMember: 'Update Member',
  years: 'years',
}
