/**
 * API config file
 */

import axios from "axios";

export default axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:3010"
      : "https://api.taekwondo-itf.ro",
});
