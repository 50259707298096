<template>
  <div>
    <v-fade-transition>
      <v-overlay :value="status" color="grey lighten-1">
        <v-progress-circular
          indeterminate
          size="64"
          color="primary"
        ></v-progress-circular>
      </v-overlay>
    </v-fade-transition>
  </div>
</template>

<script>
export default {
  props: ["status"],
};
</script>
