/**
 * AUTH module mutations
 */

import AuthConfig from "src/config/auth";
import jwtDecode from "jwt-decode";

export default {
  logoutUser(state) {
    localStorage.clear();
    state = {};
    window.location = AuthConfig.logoutUrl;
  },

  signInUserWithAuth0Success(state, authResult) {
    state.user = authResult.idTokenPayload;
    localStorage.setItem("user", JSON.stringify(state.user));

    state.idToken = authResult.idToken;
    localStorage.setItem("idToken", state.idToken);

    state.accessToken = authResult.accessToken;
    localStorage.setItem("accessToken", state.accessToken);

    state.expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
    localStorage.setItem("expiresAt", state.expiresAt);

    state.permissions = jwtDecode(authResult.accessToken).permissions;
    localStorage.setItem("permissions", JSON.stringify(state.permissions));

    state.userClub = jwt_decode(authResult.idToken)[
      "https://www.taekwon-do.ro/club"
    ];
    localStorage.setItem("userClub", JSON.stringify(state.userClub));
  },

  getUserMetaFromFrtkdDb(state, payload) {
    state.userMeta = payload;
    localStorage.setItem("userMeta", JSON.stringify(state.userMeta));
  },
};
