export default {
  breadcrumb: {
    activities: 'Activities',
  },
  activities: 'Activities',
  fee: 'Fee',
  maxAge: 'Max Age',
  maxDegree: 'Max Degree',
  minAge: 'Min Age',
  minDegree: 'Min Degree',
  name: 'Name',
}