<template>
  <v-dialog v-model="open" max-width="400">
    <v-card>
      <v-card-title class="headline error white--text lighten-1" primary-title>
        <v-icon class="px-2">ti-alert</v-icon>&nbsp;{{ $t("message.global.alert") }}
      </v-card-title>
      <v-card-text class="pa-3">{{ $t("message.global.areYouSureMessage") }}</v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="error"
          outlined
          @click.native="
            $emit('onConfirm', payload);
            close();
          "
        >
          <v-icon>mdi-delete</v-icon>&nbsp;{{ $t("message.global.confirm") }}
        </v-btn>
        <v-spacer />
        <v-btn outlined @click.native="close" color="primary">
          <v-icon>fa-times</v-icon>&nbsp;{{ $t("message.global.cancel") }}
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    open: false,
    payload: {},
  }),

  methods: {
    openDialog(payload = {}) {
      this.open = true;
      this.payload = Object.assign({}, payload);
    },

    close() {
      this.open = false;
      this.payload = {};
    },
  },
};
</script>
