<template>
  <v-app id="inspire">
    <router-view :auth="auth" :authenticated="authenticated"/>
    <notifications group="loggedIn" position="top center" animation-type="velocity"/>
  </v-app>
</template>

<script>
  import {mapGetters} from "vuex";
  import AuthService from "./lib/AuthService";

  const auth = new AuthService();
  const {authenticated, authNotifier} = auth;

  export default {
    data () {
      authNotifier.on("authChange", authState => {
        this.authenticated = authState.authenticated;
      });
      return {
        auth,
        authenticated,
        animation: {
          enter: {
            opacity: [1, 0],
            translateX: [0, -300],
            scale: [1, 0.2]
          },
          leave: {
            opacity: 0,
            height: 0
          }
        }
      };
    },

    computed: {
      ...mapGetters(["darkMode", "collapseSidebar", "boxLayout", "rtlLayout", "selectedLocale"])
    }
  };
</script>
