/**
 * AUTH module getters
 */

export default {
  accessToken: state => state.accessToken,
  expiresAt: state => state.expiresAt,
  idToken: state => state.idToken,
  permissions: state => state.permissions,
  requestHeaders: state => ({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + state.accessToken}),
  user: state => state.user,
  userClub: state => state.userClub,
  userMeta: state => state.userMeta,
}